import React from "react";
import { connect } from "react-redux";
import { Route, withRouter, Redirect } from "react-router-dom";
import { compose } from "redux";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import { Container } from "../../../style/grid";
import Header from "../../components/Header/Header";
import HospitalsDivisionsList from "../../components/HospitalsList/HospitalsDivisionsList";
import Sider from "../../components/Sider/Sider";
import { NetworkErrorPopup } from "../../components/ui/NetworkErrorPopup/NetworkErrorPopup";
import Banners from "../Banners/Banners";
import Client from "../Client/Client";
import { Divisions } from "../Divisions/Divisions";
import Employees from "../Employees/Employees";
import FavoritePatients from "../FavoritePatients/FavoritePatients";
import Hospitals from "../Hospitals/Hospitals";
import Monitoring from "../Monitoring/Monitoring";
import NewClient from "../NewClient/NewClient";
import Onboarding from "../Onboarding/Onboarding";
import PromoCodes from "../PromoCodes/PromoCodes";
import PushNotifications from "../PushNotifications/PushNotifications";
import Shedule from "../Shedule/Shedule";
import Specialization from "../Specialization/Specialization";
import SpecializationMapping from "../SpecializationMapping/SpecializationMapping";
import Subscribers from "../Subscribers/Subscribers";
import Subscription from "../Subscription/Subscription";
import Symptoms from "../Symptoms/Symptoms";
import Feedbacks from "../Feedbacks/Feedbacks";
import { useEffect } from "react";
import { wsConnected, wsDisconnected } from "../../../redux/chat/actions";

const Home = ({
  match: {
    params: { role },
  },
  wsConnected,
  wsDisconnected,
}) => {
  
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng")?.slice(0, 2) || "uk";
    moment.locale(lang);
    
    wsConnected()
    return () => wsDisconnected()
  }, [])

  return (
    <main>
      <Container className="mainRowBox">
        <Sider />
        <div className="content">
          <Header />
          <NetworkErrorPopup />
          <div className="contentContainer">
            <Route
              path="/home/admin/shedule"
              exact
              render={() => <Shedule role={role} />}
              role={role}
            />
            <Route
              path="/home/division-admin/shedule"
              exact
              render={() => <Shedule role={role} />}
              role={role}
            />
            <Route
              path="/home/division-admin/employees"
              exact
              render={() => <Employees />}
            />
            <Route
              path="/home/admin/employees"
              // qs="?page=1&period=All"
              exact
              render={() => <Employees />}
            />
            <Route
              path="/home/admin/client"
              exact
              render={() => <Client role={role} />}
            />
            <Route
              path={`/home/${role}/user`}
              exact
              render={() => <Client role={role} />}
            />
            <Route
              path={`/home/${role}/client/:patient?/:card?`}
              exact
              render={() => <Client role={role} />}
            />
            <Route
              path="/home/operator/newClient"
              exact
              render={() => <NewClient role={role} />}
            />
            <Route
              path="/home/insurance-operator/newClient"
              exact
              render={() => <NewClient role={role} />}
            />
            <Route
              path="/home/admin/specialization"
              exact
              render={() => <Specialization />}
            />
            <Route
              path="/home/admin/hospitals"
              exact
              render={() => <Hospitals />}
            />
            <Route
              path="/home/admin/hospitals/division/:id?"
              exact
              render={() => <HospitalsDivisionsList />}
            />
            <Route
              path="/home/admin/banners"
              exact
              render={() => <Banners />}
            />
            <Route
              path="/home/admin/subscription"
              exact
              render={() => <Subscription />}
            />
            <Route
              path="/home/admin/onboarding"
              exact
              render={() => <Onboarding />}
            />
            <Route
              path="/home/admin/symptoms"
              exact
              render={() => <Symptoms />}
            />
            <Route
              path="/home/admin/companies"
              exact
              render={() => <Divisions />}
            />
            <Route
              path="/home/admin/feedbacks"
              exact
              render={() => <Feedbacks role={role} />}
            />
            <Route
              path="/home/admin/spec-mapping"
              exact
              render={() => <SpecializationMapping />}
            />
            <Route
              path="/home/admin/push-notifications"
              exact
              render={() => <PushNotifications />}
            />
            <Route
              path="/home/operator/monitoring"
              qs="?page=1&period=All"
              exact
              render={() => <Monitoring role={role} />}
            />
            <Route
              path="/home/insurance-operator/monitoring"
              exact
              render={() => <Monitoring role={role} />}
            />
            <Route
              path="/home/doctor/monitoring"
              exact
              render={() => <Monitoring role={role} />}
            />
            <Route
              path="/home/doctor/monitoring/:patientId"
              exact
              render={() => <Monitoring role={role} />}
            />
            <Route
              path="/home/doctor/favorite-clients"
              exact
              render={() => <FavoritePatients role={role} />}
            />
            <Route
              path="/home/admin/subscription/subscribers/:id?"
              exact
              render={() => <Subscribers />}
            />
            <Route
              path="/home/admin/promocodes"
              exact
              render={() => <PromoCodes role={role} />}
            />
            <Route
              path="/home/insurance-admin/promocodes"
              exact
              render={() => <PromoCodes role={role} />}
            />
            {/* <Route
              path="/home/doctor/monitoring"
              exact
              render={() => <Redirect to="/home/doctor/monitoring?page=1&period=All"/>}
            />
            <Route
              path="/home/operator/monitoring"
              exact
              render={() => <Redirect to="/home/operator/monitoring?page=1&period=All"/>}
            /> */}
          </div>
        </div>
      </Container>
    </main>
  );
};
const mapStateToProps = ({ user, patients }) => ({
  user,
  patientCard: patients && patients.patientCard,
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, { wsConnected, wsDisconnected })
);

export default enhance(Home);
